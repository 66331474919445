import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@repo/ui/components/Collapsible.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
  SidebarSeparator,
  Sidebar as UISidebar,
  useSidebar,
} from '@repo/ui/components/Sidebar.js'
import type { IconName } from '@repo/ui/icons/icons'
import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'
import { NavLink } from 'react-router'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { TOPBAR_HEIGHT } from './Topbar'

type SidebarLink = {
  name: string
  path: string
  icon: IconName
  end?: boolean
}

export const SIDEBAR_LINKS: SidebarLink[] = [
  {
    name: 'Dashboard',
    path: '/',
    icon: 'gauge',
    end: true,
  },
  {
    name: 'Activity',
    path: '/activity',
    icon: 'arrow-click',
  },
  {
    name: 'Apps',
    path: '/apps',
    icon: 'apps',
  },
  {
    name: 'Categories',
    path: '/categories',
    icon: 'hierarchy',
  },
  {
    name: 'Team',
    path: '/team',
    icon: 'users',
  },
  {
    name: 'Settings',
    path: '/settings',
    icon: 'gear',
  },
]

const AdminLinks: SidebarLink[] = [
  {
    name: 'Vendors',
    path: '/admin/vendors',
    icon: 'building',
  },
  {
    name: 'Categories',
    path: '/admin/categories',
    icon: 'list',
  },
  {
    name: 'Sessions',
    path: '/admin/sessions',
    icon: 'arrow-click',
  },
]

export const Sidebar = () => {
  return (
    <UISidebar
      collapsible="icon"
      style={{ height: `calc(100svh - ${TOPBAR_HEIGHT})`, top: TOPBAR_HEIGHT }}
    >
      <SidebarContent>
        <SidebarLinks />
      </SidebarContent>
      <SidebarRail />
    </UISidebar>
  )
}

export const SidebarLinks = () => {
  const { state } = useSidebar()
  const { APP_ENV } = useClientEnvironmentVariables()
  const user = useAuthenticatedUser()

  const showAdminLinks =
    user.isInternal && state !== 'collapsed' && APP_ENV !== 'demo'

  return (
    <>
      <SidebarGroup>
        <SidebarGroupContent>
          <SidebarMenu>
            {SIDEBAR_LINKS.map((link) => (
              <SidebarMenuItem key={link.name}>
                <SidebarNavItem to={link.path} end={link.end}>
                  {({ isActive }) => (
                    <SidebarMenuButton isActive={isActive}>
                      <Icon
                        name={link.icon}
                        className={cn(isActive && 'text-brand')}
                      />
                      {link.name}
                    </SidebarMenuButton>
                  )}
                </SidebarNavItem>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

      {showAdminLinks ? (
        <>
          <SidebarSeparator className="opacity-50" />
          <Collapsible defaultOpen className="group/collapsible">
            <SidebarGroup>
              <SidebarGroupLabel
                asChild
                className="mb-1 text-sidebar-foreground text-sm hover:bg-sidebar-accent/10"
              >
                <CollapsibleTrigger
                  className={`flex items-center justify-between gap-4 [&>[data-slot="icon"]]:size-3`}
                >
                  <div className="flex items-center gap-3">
                    <Icon name="shield" />
                    <span>Admin</span>
                  </div>
                  <Icon
                    name="chevron-right"
                    className="transition-transform group-data-[state=open]/collapsible:rotate-90"
                  />
                </CollapsibleTrigger>
              </SidebarGroupLabel>
              <CollapsibleContent>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {AdminLinks.map((link) => (
                      <SidebarNavItem
                        to={link.path}
                        className="block"
                        key={link.path}
                      >
                        {({ isActive }) => (
                          <SidebarMenuItem className="pl-4">
                            <SidebarMenuButton
                              isActive={isActive}
                              className="pl-3"
                            >
                              {link.name}
                            </SidebarMenuButton>
                          </SidebarMenuItem>
                        )}
                      </SidebarNavItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        </>
      ) : null}
    </>
  )
}

const SidebarNavItem = ({ ...props }: ComponentProps<typeof NavLink>) => {
  return (
    <NavLink className="block" prefetch="intent" viewTransition {...props} />
  )
}
