import * as LabelPrimitive from '@radix-ui/react-label'
import { type VariantProps, cva } from 'class-variance-authority'
import { cn } from '#lib/utils.js'

const labelVariants = cva(
  'text-xs font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 block'
)

const Label = ({
  className,
  ...props
}: React.ComponentProps<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants>) => (
  <LabelPrimitive.Root className={cn(labelVariants(), className)} {...props} />
)

export { Label }
