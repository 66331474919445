import { z } from 'zod'
import type { handleUniversalSearch } from './handle-universal-search.server'

export type SearchResult = Awaited<
  ReturnType<typeof handleUniversalSearch>
>[number]

export const UniversalSearchSchema = z.object({
  intent: z.literal('universalSearch'),
  query: z.string().trim().min(3),
})
