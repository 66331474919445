import type { Vendor } from '@repo/db/schema'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@repo/ui/components/Avatar.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'

export const VendorIcon = ({
  src,
  className,
  ...props
}: {
  src: Vendor['iconUrl']
} & ComponentProps<typeof Avatar>) => {
  return (
    <Avatar
      {...props}
      className={cn('rounded-sm dark:bg-base-300 dark:p-0.5', className)}
    >
      <AvatarImage
        src={src}
        alt="app icon"
        className="object-contain dark:rounded-sm"
      />
      <AvatarFallback className="rounded-none bg-gray-200 text-primary/70">
        <Icon name="box-open" />
      </AvatarFallback>
    </Avatar>
  )
}
