import { pluralize } from '@repo/shared/pluralize'
import { Icon } from '@repo/ui/components/Icon.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@repo/ui/components/Tooltip.js'
import { cn } from '@repo/ui/lib/utils'
import { format } from 'date-fns'
import { Suspense } from 'react'
import { Link } from 'react-router'
import { IconStamp } from '~/components/IconStamp'
import { PageSubtitle, PageTitle } from '~/components/Page'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { requireAuth } from '~/services/auth/auth.server'
import type { Route } from './+types/route'
import { DashboardEmptyState } from './DashboardEmptyState'
import { DashboardSkeleton } from './DashboardSkeleton'
import { NewProducts } from './NewProducts'
import { PopularAppsChart } from './PopularAppsChart'
import { SessionStatistics } from './SessionStatistics'
import { UsageTrends } from './UsageTrends'
import { getNewProducts } from './get-new-products.server'
import { getPopularApps } from './get-popular-apps.server'
import { getSessionCount } from './get-session-count.server'
import { getSessionStatistics } from './get-session-statistics.server'
import { getTrackedUserCount } from './get-tracked-user-count.server'
import { getUsageTrends } from './get-usage-trends.server'

export const loader = async ({ request }: Route.LoaderArgs) => {
  const auth = await requireAuth(request)

  return {
    newProducts: getNewProducts(auth.orgId),
    popularApps: getPopularApps(auth.orgId),
    statistics: getSessionStatistics(auth),
    usageTrends: getUsageTrends(auth.orgId),
    sessionCount: await getSessionCount(auth.orgId),
    trackedUserCount: await getTrackedUserCount(auth.orgId),
  }
}

export default function DashboardRoute({ loaderData }: Route.ComponentProps) {
  const {
    newProducts,
    popularApps,
    statistics,
    usageTrends,
    sessionCount,
    trackedUserCount,
  } = loaderData

  const { PUBLIC } = useClientEnvironmentVariables()

  const org = useAuthenticatedOrg()

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <IconStamp name="home" className="size-4" />
            <PageTitle>{org.name}</PageTitle>
          </div>
          <PageSubtitle className="text-md">
            {format(new Date(), 'cccc, LLLL d')}
          </PageSubtitle>
        </div>
        <div
          className={cn(
            'flex select-none items-center gap-2 rounded-sm border border-current px-3 py-2 font-semibold text-xs',
            trackedUserCount
              ? 'border-success-400 bg-success-300/40 text-success-800 dark:bg-success-500/30 dark:text-success-200/80'
              : 'border-warning-300 bg-warning-200/50 text-warning-700 dark:bg-warning-300/30 dark:text-warning-200/80'
          )}
        >
          <div
            className={cn(
              'size-2 animate-pulse rounded-full bg-current',
              trackedUserCount
                ? 'bg-success-600 dark:bg-success-700'
                : 'bg-warning-500 dark:bg-warning-300'
            )}
          />
          <span>
            Tracking {trackedUserCount} {pluralize(trackedUserCount, 'user')}
          </span>
          {trackedUserCount ? null : (
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`${PUBLIC.DOCS_URL}/deployment`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition duration-200 hover:scale-110 hover:text-warning-600"
                >
                  <Icon name="lifebuoy" className="size-4" />
                </Link>
              </TooltipTrigger>
              <TooltipContent className="flex items-center gap-1">
                Help docs <Icon name="external-link" />
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      <Spacer size="md" />

      <Suspense fallback={<DashboardSkeleton />}>
        {sessionCount ? (
          <div className="space-y-4">
            <SessionStatistics statisticsPromise={statistics} />
            <div className="grid grid-cols-2 gap-4">
              <PopularAppsChart appsPromise={popularApps} />
              <UsageTrends appsPromise={usageTrends} />
            </div>
            <Spacer size="xs" />
            <NewProducts productsPromise={newProducts} />
          </div>
        ) : (
          <DashboardEmptyState />
        )}
      </Suspense>
    </>
  )
}
