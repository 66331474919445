import { Button } from '@repo/ui/components/Button.js'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@repo/ui/components/Dialog.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
} from '@repo/ui/components/Select.js'
import { type ComponentProps, useState } from 'react'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { useForm } from '~/hooks/useForm'
import { useMemberships } from '~/routes/_app/useMemberships'
import {
  CreateOrganizationSchema,
  SwitchOrganizationSchema,
} from '~/routes/api.organizations/route.config'
import { Form } from '../form/Form'

export const OrgPicker = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const org = useAuthenticatedOrg()
  const memberships = useMemberships()

  const { submit, isSubmitting } = useFetcherForm({
    intent: SwitchOrganizationSchema.shape.intent.value,
    config: {
      action: '/api/organizations',
    },
    onSuccess: () => setMenuOpen(false),
  })

  return (
    <>
      <Select
        value={org.id}
        onValueChange={(orgId) => submit({ orgId })}
        disabled={isSubmitting}
        open={menuOpen}
        onOpenChange={setMenuOpen}
      >
        <SelectTrigger asChild>
          <Button variant="ghost" size="sm" className="text-muted-foreground">
            <span className="font-medium ">{org.name}</span>
            <Icon name="chevrons-up-down" className="size-3.5" />
          </Button>
        </SelectTrigger>
        <SelectContent align="start" className="min-w-60">
          {memberships.map((membership) => (
            <SelectItem key={membership.orgId} value={membership.orgId}>
              <div className="font-medium">{membership.name}</div>
            </SelectItem>
          ))}

          <SelectSeparator />
          <button
            type="button"
            onClick={() => {
              setDialogOpen(true)
              setMenuOpen(false)
            }}
            className="flex items-center gap-2 px-2 py-1.5 text-sm"
          >
            <Icon name="plus" size="sm" />
            New organization
          </button>
        </SelectContent>
      </Select>

      <CreateOrgDialog open={dialogOpen} onOpenChange={setDialogOpen} />
    </>
  )
}

const CreateOrgDialog = ({ ...props }: ComponentProps<typeof Dialog>) => {
  const [form, fields] = useForm({
    schema: CreateOrganizationSchema,
    onSuccess: () => props.onOpenChange?.(false),
  })

  return (
    <Dialog {...props}>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Create an organization</DialogTitle>
          <DialogDescription className="sr-only">
            Create a new organization
          </DialogDescription>
        </DialogHeader>
        <Form form={form} action="/api/organizations">
          <DialogBody>
            <Form.Field name={fields.name.name}>
              <Form.Label>Name</Form.Label>
              <Form.Input icon="building" placeholder="e.g. Pied Piper" />
              <Form.FieldErrors />
            </Form.Field>
          </DialogBody>
          <DialogFooter>
            <Form.SubmitButton>Create</Form.SubmitButton>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
