import { Command as CommandPrimitive, useCommandState } from 'cmdk'

import { cn } from '#lib/utils.js'
import { Icon } from './Icon'
import { Spinner } from './Spinner'

const Command = ({
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive>) => (
  <CommandPrimitive
    className={cn(
      'flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
      className
    )}
    {...props}
  />
)

const CommandInput = ({
  loading,
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.Input> & {
  loading?: boolean
}) => (
  <div className={cn('relative ', className)} cmdk-input-wrapper="">
    <Icon
      name="search"
      className="absolute left-2 top-1/2 -translate-y-1/2 size-3.5 opacity-50"
    />
    <CommandPrimitive.Input
      className="pl-9 py-0 h-10 w-full rounded-md bg-background text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus:border-primary duration-300 transition-colrs outline-offset-2 border border-border"
      {...props}
    />
    {loading ? (
      <div className="absolute top-1/2 -translate-y-1/2 right-2 h-full flex items-center justify-center">
        <Spinner size="sm" className="text-muted-foreground" />
      </div>
    ) : null}
  </div>
)

const CommandList = ({
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.List>) => (
  <CommandPrimitive.List
    className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
)

const CommandEmpty = (
  props: React.ComponentProps<typeof CommandPrimitive.Empty>
) => <CommandPrimitive.Empty className="py-6 text-center text-sm" {...props} />

const CommandGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.Group>) => (
  <CommandPrimitive.Group
    className={cn(
      'overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground',
      className
    )}
    {...props}
  />
)

const CommandSeparator = ({
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.Separator>) => (
  <CommandPrimitive.Separator
    className={cn('-mx-1 h-px bg-border', className)}
    {...props}
  />
)

const CommandItem = ({
  className,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.Item>) => (
  <CommandPrimitive.Item
    className={cn(
      "relative flex cursor-pointer select-none items-center rounded-md px-2 py-2 text-sm outline-hidden data-[disabled=true]:pointer-events-none data-[selected='true']:bg-secondary data-[disabled=true]:opacity-50 [&>[data-slot='icon']]:mr-2 [&>[data-slot='icon']]:text-muted-foreground [&>[data-slot='icon']]:size-3",
      className
    )}
    {...props}
  />
)

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        'ml-auto text-xs tracking-widest text-muted-foreground',
        className
      )}
      {...props}
    />
  )
}

const CommandCreate = ({
  className,
  onSelect,
  ...props
}: React.ComponentProps<typeof CommandPrimitive.Item>) => {
  const search = useCommandState((state) => state.search)

  return (
    <CommandGroup className={cn(!search && 'hidden')}>
      <CommandItem
        className={cn('my-1 bg-secondary rounded-md font-medium', className)}
        onSelect={() => onSelect?.(search)}
        {...props}
      >
        <Icon name="circle-plus" className="size-4 mr-2" />
        Create "{search}"
      </CommandItem>
    </CommandGroup>
  )
}

const CommandLoading = CommandPrimitive.Loading

export {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  CommandCreate,
  CommandLoading,
}
