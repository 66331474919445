import { cn } from '#lib/utils.js'
import { Icon, type IconName } from './Icon'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: IconName
}

const Input = ({ className, icon, type = 'text', ...props }: InputProps) => {
  return (
    <div className="relative w-auto" data-slot="input-wrapper">
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/70 focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-muted focus-visible:border-primary transition-colors duration-300 read-only:select-none read-only:pointer-events-none read-only:opacity-50 read-only:bg-muted read-only:cursor-not-allowed',
          icon && 'peer pl-9',
          className
        )}
        {...props}
      />
      {icon ? (
        <div className="absolute left-0 top-0 flex h-full w-10 items-center justify-center text-muted-foreground/50 peer-focus:text-brand">
          <Icon name={icon} size="sm" />
        </div>
      ) : null}
    </div>
  )
}

export { Input }
