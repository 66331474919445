import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@repo/ui/components/Dialog.js'
import type { ComponentProps } from 'react'
import { Form } from '~/components/form/Form'
import { useForm } from '~/hooks/useForm'
import { SubmitAppRequestSchema } from './route.config'

export const AppRequestDialog = (props: ComponentProps<typeof Dialog>) => {
  const [form, fields] = useForm({
    schema: SubmitAppRequestSchema,
    onSuccess: () => props.onOpenChange?.(false),
  })

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Request an app</DialogTitle>
        </DialogHeader>
        <Form form={form} action="/api/requests">
          <DialogBody>
            <div className="max-w-lg space-y-4">
              <Form.Field name={fields.name.name}>
                <Form.Label>App name*</Form.Label>
                <Form.Input required autoFocus placeholder="e.g. Pied Piper" />
                <Form.FieldErrors />
              </Form.Field>

              <Form.Field name={fields.website.name}>
                <Form.Label>Website*</Form.Label>
                <Form.Input required placeholder="e.g. https://piedpiper.com" />
                <Form.FieldErrors />
              </Form.Field>

              <Form.Field name={fields.authenticatedUrl.name}>
                <Form.Label>Authenticated URL</Form.Label>
                <Form.Input placeholder="e.g. https://app.piedpiper.com" />
                <Form.FieldHint>
                  The URL you visit when you're logged in to this app. Note that
                  only web-based applications are supported.
                </Form.FieldHint>
                <Form.FieldErrors />
              </Form.Field>
            </div>
          </DialogBody>

          <DialogFooter>
            <Form.SubmitButton className="min-w-32">Submit</Form.SubmitButton>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
